<template>
    <b-modal :id="name" hide-footer okVariant="success">
        <template #modal-title>
            <h3> {{ title }} </h3>
        </template>

        <div class="d-flex product-model-image-details-wrapper flex-row p-20">
            <slot></slot>
        </div>

        <div class="text-right">
            <b-link @click="close()" class="close-arrow"><font-awesome-icon :icon="arrowDownRightIcon"
                    size="2x" /></b-link>
        </div>
    </b-modal>
</template>
<script>
import { faArrowDownRight } from '@fortawesome/pro-light-svg-icons';
export default {
    name: "TermsModel",
    props: {
        name: String,
        title: String
    },
    data() {
    return {
      arrowDownRightIcon: faArrowDownRight,
    }
    },
    methods: {
        close() {
            this.$bvModal.hide(this.name)
        },
    },
}
</script>
<style lang="scss"></style>