<template>
  <div class="ProductReview">
    <b-modal id="modal-review" okVariant="success" okTitle="Ok" :title="$t('review_send')" @ok="clickReviewOk">
      <p class="my-4">{{ $t("review_thankyou") }}</p>
    </b-modal>

    <h3>{{ $t("productreview") }}</h3>
    <div class="review-block">
      <div class="row">
        <div class="col-md-6">
          <div v-if="product != null" class="product_rating">

            <div v-if="product.reviews.items.length == 0"> {{ $t("no_reviews") }} </div>

            <div v-for="(rev, index) of product.reviews.items" :key="index">
              <div v-for="(rat, idx) of rev.ratings_breakdown" :key="idx">
                <ProductRating :title="rat.name" :stars="parseInt(rat.value)"></ProductRating>
              </div>

              <div class="nickname">{{ rev.nickname }}</div>
              <div class="summary">{{ rev.summary }}</div>
              <div class="review">{{ rev.text }}</div>
            </div>
          </div>

          <h4>{{ $t("you'rereviewing") }}</h4>
          <div v-for="(ritem, idx) of reviewMeta" :key="idx" class="review_rating">
            <ReviewRating :reviewData="ritem" v-model="ratings[idx]"></ReviewRating>
          </div>

          <div id="Nickname" role="group" class="form-group review-inputs">
            <label id="first-Nickname" for="first_name" class="d-block">{{ $t("nickname") }}</label>
            <div class="no-focus-ring">
              <input id="first_name" type="text" class="form-control no-focus-ring" aria-describedby="firstnickname-Review"
                v-model="nickname" />
              <div id="firstnickname-review" class="invalid-nickname"></div>
            </div>
          </div>

          <div id="Summary" role="group" class="form-group review-inputs">
            <label id="Summary" for="Summary" class="d-block">{{ $t("summary") }}</label>
            <div class="no-focus-ring">
              <input id="Summary" type="text" class="form-control no-focus-ring" aria-describedby="Summary-Review"
                v-model="summary" />
              <div id="Summary-review" class="invalid-Summary"></div>
            </div>
          </div>

          <div id="Review" role="group" class="form-group review-inputs">
            <label id="Review" for="Review" class="d-block">{{ $t("review") }}</label>
            <div class="no-focus-ring">
              <textarea name="Review" rows="5" v-model="text" class="form-control no-focus-ring"></textarea>
              <div id="review" class="invalid-Review"></div>
            </div>  
          </div>

          <button @click="sendReview" class="submit reviewbutton"> {{ $t("submitreview") }} </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductRating from "@/base/core/components/ProductRating";
import ReviewRating from "@/base/core/components/ReviewRating";
import { createProductReview } from "@storefront/core/data-resolver/products";
export default {
  name: "ProductReview",
  components: {
    ProductRating,
    ReviewRating,
  },
  beforeCreate() { },
  data() {
    return { test: null, nickname: "", summary: "", text: "", ratings: [] };
  },
  methods: {
    async sendReview() {
      const ratings = [];
      this.reviewMeta.forEach((element, index) => {
        if (typeof this.ratings[index] != "undefined") {
          let obj = { id: element.id, value_id: this.ratings[index] };
          ratings.push(obj);
        }
      });
      const inp = {};
      inp.sku = this.product.sku;
      inp.nickname = this.nickname;
      inp.summary = this.summary;
      inp.text = this.text;
      inp.ratings = ratings;
      this.test = inp;
      const retval = await createProductReview(inp);
      if (retval != false) {
        this.$bvModal.show("modal-review");
        this.nickname = "";
        this.summary = "";
        this.text = "";
        this.ratings.forEach((element, index) => {
          element = null;
          this.ratings[index] = null;
        });
      }
    },
    clickReviewOk() {
      this.$bvModal.hide("modal-review");
    },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      reviewMeta: "product/getProductReviewRatingsMetadata",
    }),
  },
};
</script>
