var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-content"},[_c('div',{staticClass:"d-lg-none filter-top"},[_c('span',{staticClass:"filter-collapse-heading"},[_vm._v(_vm._s(_vm.$t("filters")))]),_c('b-link',{staticClass:"lnr lnr-chevron-left",on:{"click":function($event){$event.preventDefault();return _vm.toggleFilter($event)}}})],1),(_vm.filterItemsSelected.length)?_c('div',{staticClass:"active-filters"},[_c('b-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle.active-filters",modifiers:{"active-filters":true}}],staticClass:"filter--block-heading filter--collapse"},[_vm._v(_vm._s(_vm.$t("used_filters"))+":")]),_c('b-link',{staticClass:"delete-active-filters",attrs:{"href":"#"}},[_c('i',{staticClass:"lnr lnr-cross"})]),_c('b-collapse',{attrs:{"visible":"","id":"active-filters"}},[_c('ul',{staticClass:"active-filters-list"},_vm._l((_vm.filterItemsSelected),function(activeFilterItem,index){return _c('li',{key:index},[_c('b-link',{attrs:{"href":"#"},on:{"click":function($event){return _vm.removeSelectedItem(activeFilterItem.value)}}},[_c('i',{staticClass:"lnr lnr-cross"})]),_c('span',{staticClass:"active-filter--name"},[_vm._v(_vm._s(activeFilterItem.type_label)+": "+_vm._s(activeFilterItem.label))])],1)}),0)])],1):_vm._e(),(!_vm.dontShowActiveFilters && _vm.categories.length != 0)?_c('div',[_c('div',[_c('span',{staticClass:"filter--block-heading"},[_vm._v("Kategorien")]),_c('div',{staticClass:"filter-lists"},[_c('ul',{staticClass:"category-list"},_vm._l((_vm.categories),function(categoryItem){return _c('li',{key:categoryItem.id,staticClass:"title-border"},[_c('b-link',{attrs:{"to":"/" + categoryItem.url_path}},[_c('span',{staticClass:"fake-label"},[_vm._v(" "+_vm._s(categoryItem.name)+" ")])])],1)}),0)])])]):_vm._e(),_vm._l((_vm.productFilters),function(prodFilter){return _c('div',{key:prodFilter.attribute_code},[(
      prodFilter.attribute_code != 'rating_summary' &&
      prodFilter.attribute_code != 'price'
    )?_c('div',{staticClass:"brands"},[_c('span',{staticClass:"filter--block-heading"},[_vm._v(" "+_vm._s(_vm.$t(prodFilter.label))+" ")]),_c('div',{staticClass:"brands-filter filter-lists",attrs:{"id":("brands-filter-" + (prodFilter.attribute_code))}},[_vm._l((prodFilter.options),function(brandsFilterItem,index){return _c('div',{key:index,staticClass:"title-border"},[(index < 20)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between title-border"},[_c('label',{staticClass:"d-flex align-items-center",attrs:{"for":brandsFilterItem.value}},[_c('input',{key:brandsFilterItem.value,ref:brandsFilterItem.value,refInFor:true,attrs:{"type":"checkbox","name":brandsFilterItem.label,"id":brandsFilterItem.value},domProps:{"value":"attribute_code:" +
                    prodFilter.attribute_code +
                    ",type_label:" +
                    prodFilter.label +
                    ",value:" +
                    brandsFilterItem.value +
                    ",label:" +
                    brandsFilterItem.label},on:{"click":function($event){return _vm.onChangeFilter($event)}}}),_c('span',{staticClass:"fake-input"}),_c('span',{staticClass:"fake-label"},[_vm._v(_vm._s(brandsFilterItem.label))])])])]):_c('div',[(_vm.readMore[prodFilter.attribute_code])?_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"d-flex align-items-center",attrs:{"for":brandsFilterItem.value}},[_c('input',{key:brandsFilterItem.value,ref:brandsFilterItem.value,refInFor:true,attrs:{"type":"checkbox","name":brandsFilterItem.label,"id":brandsFilterItem.value},domProps:{"value":"attribute_code:" +
                    prodFilter.attribute_code +
                    ",type_label:" +
                    prodFilter.label +
                    ",value:" +
                    brandsFilterItem.value +
                    ",label:" +
                    brandsFilterItem.label},on:{"click":function($event){return _vm.onChangeFilter($event)}}}),_c('span',{staticClass:"fake-input"}),_c('span',{staticClass:"fake-label"},[_vm._v(_vm._s(brandsFilterItem.label))])])]):_vm._e()])])}),(prodFilter.options.length > 20)?_c('div',{staticStyle:{"width":"100%","text-align":"left"}},[(!_vm.readMore[prodFilter.attribute_code])?_c('div',{staticClass:"show-more-filter"},[_c('b-link',{staticClass:"filter--block-heading",staticStyle:{"display":"block","cursor":"pointer"},on:{"click":function($event){return _vm.showMore(prodFilter.attribute_code)}}},[_vm._v(_vm._s(_vm.$t("show_more")))])],1):_vm._e(),(_vm.readMore[prodFilter.attribute_code])?_c('div',{staticClass:"show-more-filter"},[_c('b-link',{staticClass:"filter--block-heading",staticStyle:{"display":"block","cursor":"pointer"},on:{"click":function($event){return _vm.showLess(prodFilter.attribute_code)}}},[_vm._v(_vm._s(_vm.$t("show_less")))])],1):_vm._e()]):_vm._e()],2)]):_vm._e(),(
      prodFilter.attribute_code == 'rating_summary'
    )?_c('div',{staticClass:"rating_summary"},[_c('span',{staticClass:"filter--block-heading"},[_vm._v(" "+_vm._s(_vm.$t(prodFilter.label))+" ")]),_c('div',{staticClass:"brands-filter filter-lists",attrs:{"id":("brands-filter-" + (prodFilter.attribute_code))}},_vm._l((_vm.ratingFiltersCount),function(rating_index){return _c('div',{key:rating_index[0],staticClass:"title-border"},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between title-border"},[_c('label',{staticClass:"d-flex align-items-center",class:{ active: _vm.activeRating === rating_index[0] }},[_c('input',{attrs:{"type":"radio","name":"rating_summery"},domProps:{"value":"attribute_code:" +
                    prodFilter.attribute_code +
                    ",type_label:" +
                    prodFilter.label +
                    ",value:" +
                    rating_index[0] +
                    ",label:" +
                    _vm.getOptionLabel(rating_index[0])},on:{"click":function($event){return _vm.onChangeFilterRadio($event,rating_index[0])}}}),_c('Rating',{staticClass:"m-0",attrs:{"stars":rating_index[1]}}),_c('span',{staticClass:"fake-label"},[_vm._v(_vm._s(_vm.getOptionLabel(rating_index[0])))])],1)])])])}),0)]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }