<template>
    <div class="footer"
        :style="{ background: `url(${require('@/esf_kerkrade_vitanatura/assets/images/footer/monatsangebot_bg.png')})` }">
        <div class="mobile d-xs-block d-lg-none  pb-5 pt-15  ">
            <div class="d-flex d-sm-flex d-lg-flex flex-wrap flex-column justify-content-around px-10">
                <div class="col pb-15">
                    <div class="d-flex">
                        <img class="logo w-50"
                            v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/logo.png')" />
                    </div>
                </div>
                <div class="col" v-for="item in accdata"  v-bind:key="item.title" >
                    <cmsBlockSimple class="w-75 mx-auto"
                        v-if="typeof item == 'object'"
                        :identifier="item.identifier"
                        />
                </div>
                <div class="col px-25 mt-15 border-top">
                    <div>
                        <h3 class="text-uppercase font-weight-bold pt-15  pb-10">Zahlungsarten</h3>
                    </div>
                    <div class="d-flex pay_icon row-cols-6 flex-wrap justify-content-center">
                        <b-link href="#" class="align-self-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/paypal-icon-2048x1286-ha8kzb8k.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/apple-pay.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/giropay.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/card.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center">
                            <img class=" py-3 "
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/invoice-alternate.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center">
                            <img class=" py-3 "
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/s.png')" />
                        </b-link>
                    </div>
                    <div>
                        <h3 class=" font-weight-bold pt-10">Versenden</h3>
                    </div>
                    <div class="d-flex">
                        <img class="gls"
                            v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/gls.png')" />
                    </div>
                </div>
            </div>
            <div class="border-top mt-20 ">
                <p class="p-0 m-0 w-75 mx-auto text-center pt-5 h6">* Alle Preise zzgl. gesetzl. Mehrwertsteuer und
                    Versandkosten und
                    ggf. Nachnahmegebühren, wenn nicht anders angegeben.</p>
            </div>
        </div>
        <div class="desktop d-none d-lg-block pb-5 pt-15 px-10 ">
            <div class="d-flex d-sm-block d-lg-flex justify-content-around">
                <div class="col border-right">
                    <div class="d-flex justify-content-center">
                        <img class="logo"
                            v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/logo.png')" />
                    </div>
                </div>
                <div class="col border-right" v-for="item in accdata"  v-bind:key="item.title" >
                    <cmsBlockSimple class="w-75 mx-auto"
                        v-if="typeof item == 'object'"
                        :identifier="item.identifier"
                        />
                </div>
                <div class="col ">
                    <div>
                        <h3 class="text-uppercase font-weight-bold py-3 text-center">Zahlungsarten</h3>
                    </div>
                    <div class="d-flex pay_icon row-cols-4 flex-wrap justify-content-center px-10 w-100 mx-auto">
                        <b-link href="#" class="align-self-center text-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/paypal-icon-2048x1286-ha8kzb8k.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center text-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/apple-pay.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center text-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/giropay.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center text-center">
                            <img class=" py-3"
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/card.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center text-center">
                            <img class=" py-3 "
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/invoice-alternate.png')" />
                        </b-link>
                        <b-link href="#" class="align-self-center text-center">
                            <img class=" py-3 "
                                v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/s.png')" />
                        </b-link>
                    </div>
                    <div>
                        <h3 class=" font-weight-bold pt-10 text-center">Versenden</h3>
                    </div>
                    <div class="d-flex">
                        <img class="mx-auto"
                            v-bind:src="require('@/esf_kerkrade_vitanatura/assets/images/footer/gls.png')" />
                    </div>
                </div>
            </div>
            <FooterCopyRight />
        </div>
    </div>
</template>
<script>
// import FooterDeliverySeparator from "@/base/core/components/FooterDeliverySeparator";
// import FooterAccordion from "@/base/core/components/FooterAccordion";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
// import BackToTop from "@/base/core/components/core/BackToTop";
import FooterCopyRight from "@/base/core/components/FooterCopyRight";
// import ClientOnly from "vue-client-only";

export default {
    name: "Footer",
    components: {
        // FooterDeliverySeparator,
        // FooterAccordion,
        cmsBlockSimple,
        // BackToTop,
        // ClientOnly,
        FooterCopyRight,
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    computed: {
        accdata() {
            return [
                this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
                    "footer_customer_service_block"
                ),
                this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
                    "footer_vita_natura"
                ),
            ];
        },
        allCookies() {
            return this.$gtm.enabled();
        },
        deliveryPartners() {
            return this.$store.getters["sliders/getSliderByIdentifier"](
                "delivery_partners"
            );
        },
        paymentPartners() {
            return this.$store.getters["sliders/getSliderByIdentifier"](
                "payment_providers"
            );
        },
        contactData() {
            return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
                "footer_contact"
            );
        },
    },
    watch: {},
    data: () => ({}),
};
</script>
