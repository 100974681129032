<template>
  <div>
    <div class="more-products position-relative" v-if="!isTablet && !isMobile">
      <ProductCard v-for="product of products.slice(0, noProuct)" :key="product.sku" :product="product" />
    </div>


    <ClientOnly v-if="isTablet || isMobile">
      <Carousel class="more-products" :autoplay="false" :loop="false" :navigationEnabled="true" :perPage="slidePerPage"
        :scrollPerPage="false" :paginationEnabled="false" navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
        navigationNextLabel="<i class='lnr lnr-chevron-right'></i>">
        <Slide v-for="product of products" :key="product.sku" class="more-products--slide position-relative">
          <ProductCard :product="product" />
        </Slide>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";
import { mapActions } from "vuex";
import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard";
import { isTablet } from "mobile-device-detect";
import { isMobile } from "mobile-device-detect";

export default {
  name: "MoreProductsCarousel",
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ?
        import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ?
        import("vue-carousel").then((m) => m.Slide) : null,
    ProductCard,
  },
  props: {
    products: { type: Array },
    noProuct: {
      require: true
    }
  },
  mounted() {
    if (!isServer) {
      window.addEventListener("resize", this.setNoOfSlidePerPage);
      window.addEventListener("load", this.setNoOfSlidePerPage);
      this.setNoOfSlidePerPage();
    }
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.setNoOfSlidePerPage);
      window.removeEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  data() {
    return {
      slidePerPage: this.setNoOfSlidePerPage(),
      isTablet: isTablet,
      isMobile: isMobile
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    setNoOfSlidePerPage() {
      if (isServer) {
        if (this.type == "modal") {
          this.slidePerPage = 1;
        } else {
          this.slidePerPage = 4;
        }
      } else {
        if (this.type == "modal") {
          this.slidePerPage = 1;
        } else if (window.innerWidth < 576) {
          this.slidePerPage = 1;
        } else if (window.innerWidth < 768) {
          this.slidePerPage = 2;
        } else if (window.innerWidth > 767 && window.innerWidth < 992) {
          this.slidePerPage = 2;
        } else {
          this.slidePerPage = 4;
        }
      }
    },
    toggleWishListStatus(productId) {
      this.setProductStatus({ productId });
    },
  },
};
</script>
