import config from '@config';
import { Logger } from '../lib/logger';

/**
 * running on server (true or false)
 */
export const isServer = typeof window === "undefined";

export default {
    /**
     *
     * @param {float|int} amount
     * @param {boolean} showCurrencySign
     * @returns {string}
     */
    formatCurrency(amount, showCurrencySign = true) {
        var formatter;
        if (showCurrencySign) {
            formatter = new Intl.NumberFormat(config.currency.locale, {
                style: "currency",
                currency: config.currency.currency,
            });
        } else {
            formatter = new Intl.NumberFormat(config.currency.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return config.currency.useDash
                ? formatter.format(amount).replace(/,00$/, ",-")
                : config.currency.useZeros == false ? formatter.format(amount).replace(/,00$/, "") : formatter.format(amount);
        }
        return config.currency.useDash
            ? formatter.format(amount).replace(/,00$/, ",-")
            : config.currency.useZeros == false ? formatter.format(amount).replace(/,00$/, "") : formatter.format(amount);
    },

    /**
     * @param {string} postcode
     * @param {string} country
     * 
     * @return {boolean} 
     */
    validatePostcode(postcode, country) {

        const countryRegexData = {
            "AT": "^\\d{4}$",
            "BE": "^\\[1-9][0-9]{3}$/g",
            "CH": "^\\d{4}$",
            "DE": "^\\d{5}$",
            "DK": "^\\d{4}$",
            "FR": "^\\d{5}$",
            "IT": "^\\d{5}$",
            "LI": "^\\d{4}$",
            "LU": "^(?:L-)?\\d{4}$",
            "NL": "^\\d{4}\\s?[a-zA-Z]{2}$",
            "RO": "^\\d{6}$",
            "SE": "^(?:SE)?\\d{3}\\s\\d{2}$"
        }

        const countryRegex = countryRegexData[country]

        if (countryRegex) {
            const pattern = new RegExp(countryRegex);
            return pattern.test(postcode)
        } else {
            const trimmedPostcode = postcode.trim();
            const status = trimmedPostcode.length > 0 ? true : false;
            return status;
        }
    }
}
/**
 * @param {string} attribute
 * @param {mixed} value
 * 
 * @return {string}
 */
export function slugify(attribute, value) {
    const regExp = /[^0-9a-zA-Z]+/gm;
    attribute.replace(regExp, '-').toLowerCase();
    if (typeof value == 'string') {
        value = value.replace(regExp, '-').toLowerCase();
    } else if (Array.isArray(value)) {
        value.forEach((val, index) => { value[index] = val.replace(regExp, '-').toLowerCase() });
    } else {
        Logger.debug('slugify', 'helper', value)()
    }
    return attribute + ':' + value;
}

/**
 * @param {object} attribute
 * 
 * @return {object}
 */
export function slugifyAggregation(attribute) {
    attribute?.options?.forEach((option, index) => {
        option.label = slugify(attribute.attribute_code, option.label).split(':')[1];
        attribute.options[index] = option;
    });
    return attribute;
}
