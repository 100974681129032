<template>
  <div class="account-informate">
    <h1>{{ $t("change_password_info") }}</h1>
    <b-row>
      <b-col md="6">
        <b-form-group
          class="account-inputs"
          id="password-group"
          :label="$t('form_current_password')"
          label-for="password"
        >
          <b-form-input
            id="current_password"
            v-model="form.current_password"
            type="password"
            :state="form.current_passwordState"
            aria-describedby="current_password-feedback"
            @blur="validateCurrentPassword()"
          ></b-form-input>
          <client-only>
            <password-meter :password="form.current_password" />
          </client-only>
          <b-form-invalid-feedback id="current_password-feedback">
            {{ form.current_passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="password-group"
          :label="$t('form_password')"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            :state="form.passwordState"
            aria-describedby="password-feedback"
          ></b-form-input>
          <client-only>
            <password-meter :password="form.password" />
          </client-only>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="password_confirm-group"
          :label="$t('form_password_confirmation')"
          label-for="password_confirm"
        >
          <b-form-input
            id="password_confirm"
            v-model="form.password_confirm"
            type="password"
            :state="form.password_confirmState"
            aria-describedby="password_confirm-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="password_confirm-feedback">
            {{ form.password_confirmError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="form-footer">
      <b-button
        @click="submit"
        variant="info"
        class="text-white form-submit-btn"
        >{{ $t("account_info_submit") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

export default {
  name: "AccountPassword",
  components: {
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },
  data() {
    return {
      form: {
        current_password: "",
        current_passwordState: null,
        current_passwordError: "",
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
      },
      passwordFlag: false,
      confirmPasswordFlag: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
  },
  watch: {
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.form.current_passwordState != true) {
        this.validateCurrentPassword();
        if (this.current_passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        const retval = await this.$store.dispatch("user/changePassword", {
          currentPassword: this.form.current_password,
          newPassword: this.form.password,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_password_modified"),
          };
          this.clearForm();
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "danger",
            title: this.$t("account_modify"),
            text: this.$t("account_not_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      }
    },
    clearForm() {
        this.form.current_password= "";
        this.form.current_passwordState= null;
        this.form.password= "";
        this.form.passwordState= null;
        this.form.passwordTimer= null;
        this.form.password_confirm= "";
        this.form.password_confirmState= null;
        this.passwordFlag = true;
        this.confirmPasswordFlag = true;
    },
    validatePassword() {
      if(this.passwordFlag == true){
        this.form.passwordState = null;
        this.passwordFlag = false;
        return true;
      }
      else if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_to_short");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if(this.confirmPasswordFlag == true){
        this.form.password_confirmState = null;
        this.confirmPasswordFlag = false;
        return true;
      }
      if (this.form.password_confirm.length < 8) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_to_short");
      } else if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },

    validateCurrentPassword() {
      if (this.form.current_password == "") {
        this.form.current_passwordState = false;
        this.form.current_passwordError = this.$t("password_is_required_field");
      } else {
        this.form.current_passwordState = true;
      }
    },
  },
};
</script>
