import axios from "axios";
import config from "@config";
import graphql from "@graphql";
import { getCurrentLanguage, i18n } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
// import { QLStringify } from "@storefront/core/lib/qlstringify";
import store from "@/store";

/**
 * Create a Guest shopping cart in magento through graphql
 *
 * @returns shoppingcart object or false
 */
export async function createGuestCart() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query = " mutation {createEmptyCart}";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createGuestCart", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.createEmptyCart != null) {
    return retval.data.data.createEmptyCart;
  } else {
    return false;
  }
}
/**
 * Get the free shipping information from magento with graphql
 *
 * @returns object of freeshipping information
 */
export async function getFreeShipping() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    " query { freeShippingAmount { default_amount include_tax messages { countries { iso2_code } amount include_tax } } }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
    //data: { query: query },
  }).catch((e) => {
    Logger.error("freeShippingAmount", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.freeShippingAmount != null) {
    return retval.data.data.freeShippingAmount;
  } else {
    return false;
  }
}
/**
 * set shipping address on the cart with id of the address
 *
 * @param {integer} id
 * @returns true or false
 */
export async function setShippingAddressById(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let cart = await checkCart();
  if (cart) {
    const query =
      'mutation { setShippingAddressesOnCart( input: { cart_id: "' +
      (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
      '" shipping_addresses: [ { customer_address_id: ' +
      id +
      " } ] } ) { cart { shipping_addresses { prefix firstname lastname company coc vat_id street city region { code label } postcode telephone country { code label } } } } }";
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setShippingAddressById", "data-resolver carts", e)();
      throw e;
    });
    if (retval.data.data.setShippingAddressesOnCart != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_shipping_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    return false;
  }
}

/**
 * set shipping address with address object.
 *
 * @param {object} address
 * @returns true or false
 */
export async function setShippingAddress(address) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { setShippingAddressesOnCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" shipping_addresses: [ { address: {';
  if ("country_code" in address) {
    query = query + ' country_code: ' + JSON.stringify(address.country_code);
  }  if ("prefix" in address) {
    query = query + ' prefix: ' + JSON.stringify(address.prefix);
  }
  if ("street" in address) {
    query = query + ' street: ' + JSON.stringify(address.street);
  }
  if ("postcode" in address) {
    query = query + ' postcode: ' + JSON.stringify(address.postcode);
  }
  if ("city" in address) {
    query = query + ' city: ' + JSON.stringify(address.city);
  }
  if ("telephone" in address) {
    query = query + ' telephone: ' + JSON.stringify(address.telephone);
  }
  if ("firstname" in address) {
    query = query + ' firstname: ' + JSON.stringify(address.firstname);
  }
  if ("lastname" in address) {
    query = query + ' lastname: ' + JSON.stringify(address.lastname);
  }
  if ("company" in address) {
    query = query + ' company: ' + JSON.stringify(address.company);
  }
  if ("region" in address) {
    query = query + ' region: ' + JSON.stringify(address.region);
  }
  if ("region_id" in address) {
    query = query + ' region: ' + JSON.stringify(address.region_id);
  }
  if ("vat_id" in address) {
    query = query + ' vat_id: ' + JSON.stringify(address.vat_id.toUpperCase());
  }
  if ("coc" in address) {
    query = query + ' coc: ' + JSON.stringify(address.coc);
  }

  query =
    query +
    " } } ] } ) { cart { shipping_addresses { firstname lastname company street city region { code label } postcode telephone country { code label } } } } }";

  let cart = await checkCart();
  if (cart) {
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setShippingAddress", "data-resolver carts", e)();
      throw e;
    });
    if (retval.data.data.setShippingAddressesOnCart != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_shipping_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    return false;
  }
}

/**
 * set billing address on the cart with id of the address
 *
 * @param {integer} id
 * @returns true or false
 */
export async function setBillingAddressById(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let cart = await checkCart();
  if (cart) {
    const query =
      'mutation { setBillingAddressOnCart( input: { cart_id: "' +
      (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
      '" billing_address:  { customer_address_id: ' +
      id +
      "}} ) { cart { billing_address { firstname lastname company coc vat_id street city region { code label } postcode telephone country { code label } } } } }";
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setBillingAddress", "data-resolver carts", e)();
      throw e;
    });
    if (retval.data.data.setBillingAddressOnCart != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_billing_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    const msg = {
      type: "danger",
      title: "set_email_address",
      text: i18n.t('Cart is no longer valid'),
    };
    store.dispatch("messages/sendMessage", { message: msg });
    return false;
  }
}

/**
 * set billing address with address object.
 *
 * @param {object} address
 * @returns true or false
 */
export async function setBillingAddress(address) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { setBillingAddressOnCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" billing_address:  { address: {';
  if ("country_code" in address) {
    query = query + ' country_code: ' + JSON.stringify(address.country_code);
  }
  if ("street" in address) {
    query = query + ' street: ' + JSON.stringify(address.street);
  }
  if ("postcode" in address) {
    query = query + ' postcode: ' + JSON.stringify(address.postcode);
  }
  if ("city" in address) {
    query = query + ' city: ' + JSON.stringify(address.city);
  }
  if ("telephone" in address) {
    query = query + ' telephone: ' + JSON.stringify(address.telephone);
  }
  if ("firstname" in address) {
    query = query + ' firstname: ' + JSON.stringify(address.firstname);
  }
  if ("lastname" in address) {
    query = query + ' lastname: ' + JSON.stringify(address.lastname);
  }
  if ("company" in address) {
    query = query + ' company: ' + JSON.stringify(address.company);
  }
  if ("region" in address) {
    query = query + ' region: ' + JSON.stringify(address.region);
  }
  if ("region_id" in address) {
    query = query + ' region: ' + JSON.stringify(address.region_id);
  }
  if ("vat_id" in address) {
    query = query + ' vat_id: ' + JSON.stringify(address.vat_id.toUpperCase());
  }
  if ("coc" in address) {
    query = query + ' coc: ' + JSON.stringify(address.coc);
  }

  query =
    query +
    " }}} ) { cart { billing_address { firstname lastname company street city region { code label } postcode telephone country { code label } } } } }";
  let cart = await checkCart();
  if (cart) {
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setBillingAddress", "data-resolver carts", e)();
      throw e;
    });
    if (retval.data.data.setBillingAddressOnCart != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_billing_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    const msg = {
      type: "danger",
      title: "set_email_address",
      text: i18n.t('Cart is no longer valid'),
    };
    store.dispatch("messages/sendMessage", { message: msg });
    return false;
  }
}

/**
 * get the customer cart token from magento through graphql
 *
 * @returns cart token or false
 */
export async function getCustomerCartToken() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  const query = "{ customerCart{id}}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("getCustomerCartToken", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.customerCart.id != null) {
    return retval.data.data.customerCart.id;
  } else {
    return false;
  }
}

/**
 * Check if cart exists
 * 
 * @returns true or false
 */
export async function checkCart() {
  if (store.getters["cart/getCartServerToken"] || localStorage.getItem("cartServerToken")) {
    return true;
  } else {
    return false;
  }
}

/**
 * load the cart from magento through graphql
 *
 * @returns cart object or false
 */
export async function loadCart() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }
  const query =
    '{ cart(cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '")' +
    graphql.queryFields.cart +
    "}";
  const retval = await axios({
    url: config.shop.graphQLURL, // + "?query=" + encodeURIComponent(query),
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("loadCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.cart != null) {
    return retval.data.data.cart;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * get available payment methods for this customer
 *
 * @returns array of payment methods or false
 */
export async function getPaymentmethods() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }
  const query =
    '{ getPaymentMethods(cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '") { label code additional_fields { code label options {label value} type } instructions } }';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.getPaymentMethods != null) {
    return retval.data.data.getPaymentMethods;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * set the customer email on the cart in magento through graphql
 *
 * @param {string} email
 * @returns true or false
 */
export async function setEmailToCart(email) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  // let cart = await checkCart();
  let cart = true;
  if (cart) {
    const query =
      'mutation { setGuestEmailOnCart( input: { cart_id: "' +
      (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
      '" email: "' +
      email +
      '"' +
      " } ) { cart { email } } }";
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setEmailToCart", "data-resolver carts", e)();
      throw e;
    });
    if (retval.data.data.setGuestEmailOnCart != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_email_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    const msg = {
      type: "danger",
      title: "set_email_address",
      text: i18n.t('Cart is no longer valid'),
    };
    store.dispatch("messages/sendMessage", { message: msg });
    return false;
  }
}
/**
 * merge the guest cart with the customer cart
 *
 * @param {string} oldToken
 * @param {string} newToken
 * @returns cart object or false
 */
export async function mergeCart(oldToken, newToken) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  const query =
    'mutation { mergeCarts(source_cart_id: "' +
    oldToken +
    '", destination_cart_id: "' +
    newToken +
    '") { items { id product { name sku } quantity } } }';
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("mergeCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.mergeCarts != null) {
    return retval.data.data.mergeCarts;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * Add Simple product to the cart
 *
 * @param {object} item
 * @returns true or false
 */
export async function addSimpleProduct(item) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }
  let cartId = store.getters["cart/getCartServerToken"]
  let cart = await checkCart();
  if (!cart) {
    if (store.getters["user/getIsLoggedIn"] != true) {
      cartId = await createGuestCart();
    }
    else {
      return store.dispatch("user/sessionExpired");
    }
  }
  let query =
    'mutation { addSimpleProductsToCart( input: { cart_id: "' +
    cartId +
    '" ';
  query = query + "cart_items:[" + item + "] }";
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("mergeCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.addSimpleProductsToCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }

    return false;
  }
}

/**
 * Add Sample product to the cart
 *
 * @param {object} item
 * @returns true or false
 */
export async function addSampleProductToCart(sku) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }
  let cartId = store.getters["cart/getCartServerToken"]
  let cart = await checkCart();
  if (!cart) {
    if (store.getters["user/getIsLoggedIn"] != true) {
      cartId = await createGuestCart();
    }
    else {
      return store.dispatch("user/sessionExpired");
    }
  }
  
  let query =
    'mutation { addSampleProductToCart( input: { cart_id: "' +
    cartId +
    '" ';
  query = query + 'sku:"' + sku + '" }';
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("mergeCart", "data-resolver carts", e)();
    throw e;
  });

  if (retval) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }

    return false;
  }
}

/**
 * Add Simple product to the cart
 *
 * @param {object} item
 * @returns true or false
 */
export async function addProduct(items) {
  let q = null;
  if (typeof items != "object") {
    q = items;
  } else {
    q = JSON.stringify(items);
  }
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let cartId = store.getters["cart/getCartServerToken"]
  let cart = await checkCart();
  if (!cart) {
    if (store.getters["user/getIsLoggedIn"] != true) {
      cartId = await createGuestCart();
    }
    else {
      return store.dispatch("user/sessionExpired");
    }
  }
  let query =
    'mutation { addProductsToCart( cartId: "' +
    cartId +
    '" ';
  query = query + "cartItems:" + q;
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("mergeCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.addProductsToCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }

    return false;
  }
}

/**
 * add configurable product to the cart
 *
 * @param {object} item
 * @returns true or false
 */
export async function addConfigurableProduct(item) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let cartId = store.getters["cart/getCartServerToken"]
  let cart = await checkCart();
  if (!cart) {
    if (store.getters["user/getIsLoggedIn"] != true) {
      cartId = await createGuestCart();
    }
    else {
      return store.dispatch("user/sessionExpired");
    }
  }
  let query =
    'mutation { addConfigurableProductsToCart( input: { cart_id: "' +
    cartId +
    '" ';
  query = query + "cart_items:[" + item + "] }";
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("addConfigurableProduct", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.addConfigurableProductsToCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }

    return false;
  }
}

/**
 * add bundle product to the cart
 *
 * @param {object} item
 * @returns true or false
 */
export async function addBundleProduct(item) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  await loadCart();
  let query =
    'mutation { addBundleProductsToCart( input: { cart_id: "' +
    store.getters["cart/getCartServerToken"] +
    '" ';
  query = query + "cart_items:[" + item + "] }";
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("addBundleProductsToCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.addBundleProductsToCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }

    return false;
  }
}

/**
 * Set Shipping Method on Cart
 *
 * @param {string} carrier
 * @param {string} method
 * @returns
 */
export async function setShippingMethodsOnCart(carrier, method) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  const query =
    'mutation { setShippingMethodsOnCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" shipping_methods: [ { carrier_code: "' +
    carrier +
    '" method_code: "' +
    method +
    '"' +
    " } ] } ) {  cart { shipping_addresses { selected_shipping_method { carrier_code carrier_title method_code method_title amount { value currency } } } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("setShippingMethodsOnCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.setShippingMethodsOnCart != null) {
    const paymentmethods = await getPaymentmethods();
    store.commit("cart/setPaymentMethods", paymentmethods);
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: "set_email_address",
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * add payment method to the cart
 *
 * @param {string} code
 * @returns true or false
 */
export async function setPaymentMethodOnCart(code) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  const query =
    'mutation { setPaymentMethodOnCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" payment_method: { code: "' +
    code +
    '"' +
    " }  } ) { cart { selected_payment_method { code title} } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("setPaymentMethodOnCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.setPaymentMethodOnCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: "set_email_address",
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * update the quantity of a cart item
 *
 * @param {integer} id
 * @param {integer} quantity
 * @returns true or false
 */
export async function updateCartItem(id, quantity) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { updateCartItems( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" ';
  query =
    query +
    "cart_items:[ {cart_item_id:" +
    id +
    " quantity: " +
    quantity +
    "}] }";
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("updateCartItems", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.updateCartItems != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * remove item from cart
 *
 * @param {integer} id
 * @returns true or false
 */
export async function removeCartItem(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { removeItemFromCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" ';
  query = query + "cart_item_id:" + id + "}";
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("removeItemFromCart", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.removeItemFromCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * add coupon code to cart
 *
 * @param {string} code
 * @returns true or false
 */
export async function addCouponCode(code) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { applyCouponToCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" ';
  query = query + 'coupon_code:' + JSON.stringify(code) + '}';
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("addCouponCode", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.applyCouponToCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * remove coupon code to cart
 *
 * @param {string} code
 * @returns true or false
 */
export async function removeCouponCode() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];
  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { removeCouponFromCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" ';
  query = query + '}';
  query =
    query + " ) { cart { items { id product { name sku } quantity } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("removeCouponCode", "data-resolver carts", e)();
    throw e;
  });
  if (retval.data.data.removeCouponFromCart != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          title: i18n.t("Add to cart"),
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * set payment method to cart
 *
 * @returns paymentmethod or false
 */

export async function setPaymentMethod() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }
  const paymentOptions = store.getters["cart/getPaymentMethodOptions"];
  const options = paymentOptions[store.getters["cart/getPaymentMethod"]];
  let queryOptions = "";
  if (options != null) {
    const keys = Object.keys(
      paymentOptions[store.getters["cart/getPaymentMethod"]]
    );
    keys.forEach((element) => {
      queryOptions = queryOptions =
        '{ code: "' +
        element +
        '" value: "' +
        paymentOptions[store.getters["cart/getPaymentMethod"]][element] +
        '"} ';
    });
    if (queryOptions != "") {
      queryOptions = "additional_data:[" + queryOptions + "]";
    }
  }
  /*
  const keys = Object.keys(
    paymentOptions[store.getters["cart/getPaymentMethod"]]
  );
  */
  let query =
    'mutation { setPaymentMethod( input:  { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '"  payment_method:{ code:"' +
    store.getters["cart/getPaymentMethod"] +
    '" ' +
    queryOptions +
    "}";
  if (store.getters["cart/getComment"] != "") {
    query =
      query + ' customer_note: "' + store.getters["cart/getComment"] + '" ';
  }
  if (config.createAccountPossible) {
    let create_account = store.getters['cart/getCreateAccount'];
    let password = store.getters['cart/getAccountPassword'];
    query = query + (create_account ? 'create_account:' + create_account + ' password:"' + password + '"' : '')
  }
  query = query + "}) {order { id, increment_id hash } redirect_url }}";
  //return false;
  let cart = await checkCart();
  if (cart) {
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setPaymentMethod", "data-resolver carts", e)();
      throw e;
    });
    store.commit("cart/setAccountPassword", null);
    if (retval.data.data.setPaymentMethod != null) {
      return retval.data.data.setPaymentMethod;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_shipping_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    const msg = {
      type: "danger",
      title: "set_email_address",
      text: i18n.t('Cart is no longer valid'),
    };
    store.dispatch("messages/sendMessage", { message: msg });
    return false;
  }
}

/**
 *
 * @returns order object
 */
export async function getSuccessOrder(increment_id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    'query { order(orderNumber: "' +
    increment_id +
    '") { id ' + (config.showCustomerEmail ? 'customer_email' : '') + ' number comments { timestamp message } items { id product_name product_sku quantity_ordered status product_sale_price {value} } order_date payment_methods { additional_data { name value } name type } shipping_method status total { subtotal { value } total_tax { value } total_shipping {value} taxes { title amount {value} rate } base_grand_total { currency value } grand_total { currency value } } }}';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
    //data: { query: query },
  }).catch((e) => {
    Logger.error("order", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.order != null) {
    return retval.data.data.order;
  } else {
    return false;
  }
}

/**
 *
 * @returns order object
 */
export async function getOrderInfoFromHash(hash_id) {
  store.commit('cart/setServerToken', null);
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    'query { order(hash: "' + hash_id + '") ' + graphql.queryFields.orderInfo;
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
    //data: { query: query },
  }).catch((e) => {
    Logger.error("order", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.order != null) {
    return retval.data.data.order;
  } else {
    return false;
  }
}

export async function getPickupLocations() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    "query { pickupLocations( pageSize: 20 ) { items { pickup_location_code name phone postcode country_id } } }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
    //data: { query: query },
  }).catch((e) => {
    Logger.error("order", "data-resolver carts", e)();
    console.log(e);
    throw e;
  });
  if (retval.data.data.pickupLocations != null) {
    return retval.data.data.pickupLocations;
  } else {
    return false;
  }
}

export async function setPickupLocation(location, address, address_id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  let query =
    'mutation { setShippingAddressesOnCart( input: { cart_id: "' +
    (store.getters["cart/getCartServerToken"] ?? localStorage.getItem("cartServerToken")) +
    '" shipping_addresses: [ { ';
  if (address_id != null) {
    query = query + "customer_address_id: " + address_id;
  } else {
    query = query + " address: {";
    if ("country" in address) {
      query = query + ' country_code: ' + JSON.stringify(address.country.code);
    }
    if ("street" in address) {
      query = query + ' street: ' + JSON.stringify(address.street);
    }
    if ("postcode" in address) {
      query = query + ' postcode: ' + JSON.stringify(address.postcode);
    }
    if ("city" in address) {
      query = query + ' city: ' + JSON.stringify(address.city);
    }
    if ("telephone" in address) {
      query = query + ' telephone: ' + JSON.stringify(address.telephone);
    }
    if ("firstname" in address) {
      query = query + ' firstname: ' + JSON.stringify(address.firstname);
    }
    if ("lastname" in address) {
      query = query + ' lastname: ' + JSON.stringify(address.lastname);
    }
    if ("company" in address) {
      if (address.company != null) {
        query = query + ' company: ' + JSON.stringify(address.company);
      }
    }
    /*
    if ("region" in address) {
      query = query + ' region: ' + JSON.stringify(address.region);
    }
    if ("region_id" in address) {
      query = query + ' region: ' + JSON.stringify(address.region_id);
    }
    */
  }

  query =
    query +
    ' } pickup_location_code: "' +
    location +
    '"' +
    " } ] } ) { cart { shipping_addresses { firstname lastname company street city region { code label } postcode telephone country { code label } pickup_location_code} } } }";
  let cart = await checkCart();
  if (cart) {
    const retval = await axios({
      url: config.shop.graphQLURL,
      method: "POST",
      headers: headers,
      data: { query: query },
    }).catch((e) => {
      Logger.error("setPickupLocation", "data-resolver carts", e)();
      throw e;
    });
    if (retval.data.data.setShippingAddressesOnCart != null) {
      return true;
    } else {
      if (retval.data.errors != null) {
        retval.data.errors.forEach((element) => {
          if (store.getters["user/getIsLoggedIn"] != false) {
            if (element.extensions.category === "graphql-authorization") {
              return store.dispatch("user/sessionExpired");
            }
          }
          const msg = {
            type: "danger",
            title: "set_shipping_address",
            text: element.message,
          };
          store.dispatch("messages/sendMessage", { message: msg });
        });
      }
      return false;
    }
  } else {
    const msg = {
      type: "danger",
      title: "set_email_address",
      text: i18n.t('Cart is no longer valid'),
    };
    store.dispatch("messages/sendMessage", { message: msg });
    return false;
  }
}
