<template>
    <b-form-group :id="fieldId" :label="field.label" :label-for="fieldId">

        <div v-if="this.field.type != 'checkbox'">

            <component :is="fieldComponent" :class="fieldComponent == 'b-form-select' ? 'form-control' : ''"
                :id="fieldId" :name="field.name" :value="field.value" :placeholder="field.placeholder"
                :aria-describedby="fieldId + '-feedback'" :options="field.options" :state="state" @input="updateValue" @change="updateValue">
            </component>

            <b-form-invalid-feedback :id="fieldId + '-feedback'"> {{ error }} </b-form-invalid-feedback>
        </div>

        <div v-else>

            <component :is="fieldComponent" class="form-check-input" :name="field.name"
                :aria-describedby="fieldId + '-feedback'" :state="state" @input="updateValue" @change="updateValue"> </component>

            <span v-if="!!error" class="checkbox-error"> {{ error }} </span>
        </div>
    </b-form-group>
</template>

<script>
export default {
    props: {
        field: Object,
        idx: Number,
        index: Number,
        modelValue: [String, Number, Boolean],
        state: Boolean,
        error: String,
    },
    computed: {
        fieldId() {
            return `set${this.idx}-field${this.index}`;
        },
        fieldComponent() {

            switch (this.field.type) {
                case 'textarea':
                    return 'b-form-textarea';
                case 'select':
                    return 'b-form-select';
                case 'checkbox':
                    return 'b-form-checkbox';
                case 'radio':
                    return 'b-form-radio';
                default:
                    return 'b-form-input';
            }
        },
    },
    methods: {
        updateValue(newValue) {
            this.$emit('update', { "idx": this.idx, "index": this.index, "newValue": newValue });
        }
    }
};
</script>