<template>
  <div>
    <b-modal id="modal-dynamic_form" okVariant="success" okTitle="Ok" @ok="clickDynFormOk">
      <div v-html="msg"></div>
    </b-modal>

    <h2>{{ form.title }}</h2>

    <form ref="contactForm">
      <div v-for="(set, idx) in form.fieldsets" :key="`set-${idx}`" :class="formWrapperClass">

        <div v-if="idx == 0" class="form-row">
          <div v-for="(field, index) in set.fields" :key="`field-${index}`"
            :class="index >= 3 ? (index > 4 ? 'col-md-12' : 'col-md-6') : 'col-md-4'">
            <FormInput :key="`input-${index}`" :field="field" :idx="idx" :index="index" v-model="formValue[idx][index]"
              :state="formState[idx][index]" :error="formError[idx][index]" @update="updateFormState($event)" />
          </div>
        </div>

        <div v-if="idx > 0">
          <div v-for="(field, index) in set.fields" :key="`field-${index}`">
            <FormInput :key="`input-${index}`" :field="field" :idx="idx" :index="index" v-model="formValue[idx][index]"
              :state="formState[idx][index]" :error="formError[idx][index]" @update="updateFormState($event)" />
          </div>
        </div>
      </div>
      <b-button @click="sendForm" type="button" variant="info">{{ $t('send') }}</b-button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendForm } from '@storefront/core/data-resolver/forms';
import FormInput from './FormInput.vue'; // Assuming you create a reusable component for form inputs

export default {
  name: 'DynamicForms',
  props: {
    category_id: {
      type: Number,
      required: false,
    },
    product_sku: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    formWrapperClass: {
      type: String,
    },

  }, components: {
    FormInput
  },
  data() {
    return {
      formValue: [],
      formState: [],
      formError: [],
      msg: '',
    };
  },
  computed: {
    ...mapGetters({
      product: 'product/getCurrentProduct',
      category: 'category/getCurrentCategory',
    }),
    remote_entity_id() {
      if (this.type === 'product') {
        return `remote_entity_id: "${this.product_sku}"`;
      } else if (this.type === 'category') {
        return `remote_entity_id: "${this.category_id}"`;
      }
      return '';
    },
    form() {
      if (this.type === 'product') {
        return this.$store.getters['forms/getProductForm'];
      } else if (this.type === 'category') {
        return this.$store.getters['forms/getCategoryForm'];
      }
      return this.$store.getters['forms/getContactForm'];
    },
  },
  watch: {
    product() {
      if (this.type === 'product') {
        this.resetFormValues();
      }
    },
    category() {
      if (this.type === 'category') {
        this.resetFormValues();
      }
    },
  },
  created() {
    this.resetFormValues();
  },
  methods: {
    resetFormValues() {
      const fValue = [];
      const fState = [];
      const fError = [];
      this.form.fieldsets.forEach((set, idx) => {
        fValue[idx] = [];
        fState[idx] = [];
        fError[idx] = [];
        set.fields.forEach((field, index) => {
          fValue[idx][index] = '';
          fState[idx][index] = null;
          fError[idx][index] = '';
        });
      });
      this.formValue = fValue;
      this.formState = fState;
      this.formError = fError;
    },
    clickDynFormOk() {
      this.$bvModal.hide('modal-dynamic_form');
    },
    resetContactForm(){
      this.$refs.contactForm.reset(); 
    },
    async sendForm() {
      let save = true;

      await this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach(async (field, index) => {
          let state = true;
          let error = '';
          field.validation.forEach((rule) => {
            if (!this.validateField(rule, idx, index, this.formValue[idx][index])) {
              state = false;
              error = this.formError[idx][index] || 'Ungültige Eingabe';
              save = false;
            }
          });

          this.$set(this.formState[idx], index, state);
          this.$set(this.formError[idx], index, error);
        });
      });
      if (save) {
        const input = this.buildFormInput();
        const retval = await sendForm(input);
        if (!retval.has_errors) {
          this.resetFormValues()
          
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: retval.thank_you_message,
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          setTimeout(() => {
            this.resetContactForm()
          }, 100);
        }

      }
    },
    buildFormInput() {
      let input = `type: ${this.type} ${this.remote_entity_id} field: [`;
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          if(field.type == "checkbox"){
            var checkValue = this.formValue[idx][index] == true ? field.checkedValue : field.uncheckedValue
            input += `{ name: "${field.name}", value: "${checkValue}" },`;
          }else{
          input += `{ name: "${field.name}", value: "${this.formValue[idx][index]}" },`;
          }
        });
      });
      input = input.slice(0, -1) + ']';
      return input;
    },
    resetForm() {
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          this.$set(this.formValue[idx], index, '');
          this.$set(this.formState[idx], index, null);
          this.$set(this.formError[idx], index, '');
        });
      });
    },
    updateFormState(payload) {
      const { newValue } = payload;

      this.$set(this.formValue[payload.idx], payload.index, newValue);

      let state = true;
      let error = '';

      const field = this.form.fieldsets[payload.idx].fields[payload.index];

      if (field.validation) {
        field.validation.forEach((rule) => {
          if (!this.validateField(rule, payload.idx, payload.index, newValue)) {

            state = false;
            error = rule.message || 'Ungültige Eingabe';
          }
        });
      }

      this.$set(this.formState[payload.idx], payload.index, state);
      this.$set(this.formError[payload.idx], payload.index, error);
    },
    validateField(rule, idx, index, value) {
      switch (rule.type) {
        case 'required':
          return !!value;
        case 'email':
          return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
        case "alphanum_with_spaces":
          return /^[a-zA-Z0-9\s]+$/.test(value);
        default:
          return true;
      }
    },
  },
};
</script>
