<!-- components/Loader.vue -->
<template>
    <div v-if="isLoading" class="loader-overlay">
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        }
    }
};
</script>

<style scoped>
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffffeb;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner-border {
    width: 5rem;
    height: 5rem;
}
</style>
