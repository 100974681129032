<template>
  <div id="hero-slider">
    <div class="slider-wrap">
      <VueSlickCarousel :slidesToShow="2" :dots="true" :arrows="false" :infinite="false" :responsive='[
        {
          "breakpoint": 9999,
          "settings": "unslick"
        },
        {
          "breakpoint": 992,
          "settings": {
            "slidesToShow": 1
          }
        }
        ]'>
      <template v-for="(bSlide, index) in slider.slides">
        <div class="col slide p-15" :key="index"
          v-bind:style="{ 'background-image': 'url(' + require('@/esf_kerkrade_vitanatura/assets/images/slider/hero-slider-background.jpeg') + ')' }">
          <div class="d-flex  ">
            <div v-if="index == 1" class="img-container">
                <img :src=bSlide.optImage :alt=bSlide.title>
            </div>
            <div class="card-container w-100 p-0 d-flex">
              <div class="card w-100">
                <div class="card-body p-0">
                  <h5 class="card-title" v-html="bSlide.title"></h5>
                  <h6 class="card-text" v-html="bSlide.description"></h6>
                  <b-link :to="bSlide.link" class="slider-link" v-html="bSlide.buttonText"></b-link>
                </div>
              </div>
            </div>
            <div v-if="index == 0" class="img-container">
                <img :src=bSlide.optImage :alt=bSlide.title>
            </div>
          </div>
        </div>
      </template>
      </VueSlickCarousel>
    </div>
  </div>

</template>

<script>
// import { isMobile, isTablet, isBrowser } from "mobile-device-detect";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "HeroBanner",
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    slider() {
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          element.optImage = element.media.url;
          // if (this.mobileCheck == "browser") {
          // } else if (this.mobileCheck == "tablet") {
          //   element.optImage = element.media.larger;
          // } else {
          //   element.optImage = element.media.medium;
          // }
        });
      }
      return slider;
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  components: { VueSlickCarousel }
};
</script>
