var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ClientOnly',[_c('VueSlickCarousel',{attrs:{"dots":false,"arrows":true,"slidesToShow":4,"slidesToScroll":1,"infinite":true,"responsive":[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2 
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            } ]}},_vm._l((_vm.products),function(product){return _c('ProductCard',{key:product.id,attrs:{"product":product}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }