<template>
  <AppLayout wrapperClass="contact">
    <!-- Breadcrumb navigation -->
    <Breadcrumbs />

    <!-- Main content section with padding-top for layout spacing -->
    <section class="static pt-7">
      <b-container>
        <b-row>
          <!-- Sidebar section, visible only on larger screens (lg and up) -->
          <!-- <b-col lg="3" class="d-none d-lg-block">
            <div class="sidebar">
              <span class="sidebar-heading">Links</span>
              <cmsBlockSimple identifier="side-menu-cms" />
            </div> 
          </b-col>-->

          <!-- CMS content section, displaying dynamic title and content -->
          <!-- <b-col v-if="getCmsData != null" lg="4">
            <h1>{{ getCmsData.title }}</h1>
            <v-runtime-template v-if="getCmsData != null && getCmsData.content"
              :template="`<div class='cms-content'>${getCmsData.content}</div>`"></v-runtime-template>
          </b-col> -->

          <!-- Contact form section, conditionally displaying form if data is available -->
          <b-col cols="12">
            <section class="">
              <!-- <span class="best-seller--category">{{ $t("contact_form") }}</span> -->
              <div class="row">
                <div class="best-seller--card-holder">
                  <div v-if="contactForm != null">
                    <dynamic-forms type="contact" formWrapperClass="contact-form" />
                  </div>
                </div>
              </div>
            </section>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </AppLayout>
</template>

<script>
// Import necessary components for the layout, CMS content, breadcrumbs, and form
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
// import VRuntimeTemplate from "v-runtime-template";
import DynamicForms from "@/esf_kerkrade_vitanatura/core/components/form/DynamicForms";

export default {
  name: "Contact",
  components: {
    AppLayout,
    Breadcrumbs,
    // cmsBlockSimple,
    // VRuntimeTemplate,
    DynamicForms,
  },

  computed: {
    /**
     * Fetches CMS data for the contact page from the store using a specific identifier.
     * @returns {Object|null} Contains title and content for the contact page if available; otherwise, null.
     */
    // getCmsData() {
    //   return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    //     "contact_page_block"
    //   );
    // },

    /**
     * Retrieves the contact form configuration from the store.
     * @returns {Object|null} Contact form data if available; otherwise, null.
     */
    contactForm() {
      return this.$store.getters["forms/getContactForm"];
    },
  },

  mounted() {
    /**
     * Sets breadcrumb data for the current "Contact" page.
     */
    const bcrumb = { current: this.$t("contact_page_title"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  metaInfo() {
    /**
     * Meta tag information for the contact page (SEO).
     * @returns {Object} Metadata with title, keywords, and description.
     */
    return {
      meta: [
        { name: "title", content: this.$t("contact_page_title") },
        { name: "keywords", content: this.$t("contact_page_title") },
        { name: "description", content: this.$t("contact_page_title") },
      ],
    };
  },

  data: () => ({}), // No additional reactive data needed in this component
};
</script>

<style lang="scss">
.contact {
  background-image: url("@/esf_kerkrade_vitanatura/assets/images/contact-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: 2.6rem;
    margin-bottom: 27px;
  }

  .best-seller--card-holder {
    background: #fff;
    border-radius: 5px;
    margin-block: 25px 70px;

    .custom-control-input:checked ~ .custom-control-label:before {
      background: #eb7a1c;
      border-color: #eb7a1c;
    }

    div#set1-field0 {
      display: flex;
      margin: 0px 10px;
    }

    .custom-control-label:after,
    .custom-control-label:before {
      width: 1.6rem !important;
      height: 1.6rem !important;
      border-radius: 4px;
    }

    label#set1-field0__BV_label_ {
      order: 2;
      margin-left: 10px;
    }

    .form-control {
      font-size: 13px;
    }

    button {
      border-radius: 5px !important;
      padding-inline: 30px;
    }

    .form-control.is-invalid,
    .form-control.is-valid {
      background-position: right 1.3rem center, right 2.3rem center !important;
    }

    #set1-field0 {
      .bv-no-focus-ring {
        width:0px;
      }

      .checkbox-error {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #ff0007;
        text-wrap: nowrap;
        margin:-10px;
      }
    }
  }
}
</style>
