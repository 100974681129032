<template>
  <div class="filter-content">
    <div class="d-lg-none filter-top">
      <span class="filter-collapse-heading">{{ $t("filters") }}</span>
      <b-link class="lnr lnr-chevron-left" @click.prevent="toggleFilter"></b-link>
    </div>

    <!-- <div
      class="active-filters"
      v-if="isPriceFilterActive || filterItemsSelected.length"
    > -->
    <div class="active-filters" v-if="filterItemsSelected.length">
      <b-link class="filter--block-heading filter--collapse" v-b-toggle.active-filters>{{ $t("used_filters")
        }}:</b-link>
      <b-link href="#" class="delete-active-filters"><i class="lnr lnr-cross"></i></b-link>
      <b-collapse visible id="active-filters">
        <ul class="active-filters-list">
          <!-- <li v-if="isPriceFilterActive">
            <b-link @click="removePriceFilter">
              <i class="lnr lnr-cross"></i>
            </b-link>
            <span class="active-filter--name">
              {{ `${priceFilterLabel ? priceFilterLabel : "Prijs"}` }}:
              {{ priceFilter[0] + " - " + priceFilter[1] }}
            </span>
          </li> -->
          <li v-for="(activeFilterItem, index) of filterItemsSelected" :key="index">
            <b-link @click="removeSelectedItem(activeFilterItem.value)" href="#"><i class="lnr lnr-cross"></i></b-link>
            <span class="active-filter--name">{{ activeFilterItem.type_label }}:
              {{ activeFilterItem.label }}</span>
          </li>
        </ul>
      </b-collapse>
    </div>
    <div v-if="!dontShowActiveFilters && categories.length != 0">
      <div>
        <span class="filter--block-heading">Kategorien</span>
        <div class="filter-lists">
          <ul class="category-list">
            <li class="title-border" v-for="categoryItem in categories" :key="categoryItem.id">
              <b-link :to="`/` + categoryItem.url_path">
                <span class="fake-label">
                  {{
                  categoryItem.name
                  }}
                </span>
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-for="prodFilter of productFilters" :key="prodFilter.attribute_code">

      <div class="brands" v-if="
        prodFilter.attribute_code != 'rating_summary' &&
        prodFilter.attribute_code != 'price'
      ">
        <span class="filter--block-heading">
          {{ $t(prodFilter.label) }}
        </span>
        <div :id="`brands-filter-${prodFilter.attribute_code}`" class="brands-filter filter-lists">
          <div class="title-border" v-for="(brandsFilterItem, index) in prodFilter.options" :key="index">
            <div v-if="index < 20">
              <div class="d-flex justify-content-between title-border">
                <label :for="brandsFilterItem.value" class="d-flex align-items-center">
                  <input type="checkbox" :key="brandsFilterItem.value" :name="brandsFilterItem.label"
                    :id="brandsFilterItem.value" :ref="brandsFilterItem.value" :value="`attribute_code:` +
                      prodFilter.attribute_code +
                      `,type_label:` +
                      prodFilter.label +
                      `,value:` +
                      brandsFilterItem.value +
                      `,label:` +
                      brandsFilterItem.label
                      " @click="onChangeFilter($event)" />
                  <span class="fake-input"></span>
                  <span class="fake-label">{{ brandsFilterItem.label }}</span>
                </label>

              </div>
            </div>
            <div v-else>
              <div v-if="readMore[prodFilter.attribute_code]" class="d-flex justify-content-between">
                <label :for="brandsFilterItem.value" class="d-flex align-items-center">
                  <input type="checkbox" :key="brandsFilterItem.value" :name="brandsFilterItem.label"
                    :id="brandsFilterItem.value" :ref="brandsFilterItem.value" :value="`attribute_code:` +
                      prodFilter.attribute_code +
                      `,type_label:` +
                      prodFilter.label +
                      `,value:` +
                      brandsFilterItem.value +
                      `,label:` +
                      brandsFilterItem.label
                      " @click="onChangeFilter($event)" />
                  <span class="fake-input"></span>
                  <span class="fake-label">{{ brandsFilterItem.label }}</span>
                </label>

              </div>
            </div>
          </div>
          <div style="width: 100%; text-align: left" v-if="prodFilter.options.length > 20">
            <div class="show-more-filter" v-if="!readMore[prodFilter.attribute_code]">
              <b-link class="filter--block-heading" style="display: block; cursor: pointer"
                @click="showMore(prodFilter.attribute_code)">{{ $t("show_more") }}</b-link>
            </div>
            <div class="show-more-filter" v-if="readMore[prodFilter.attribute_code]">
              <b-link class="filter--block-heading" style="display: block; cursor: pointer"
                @click="showLess(prodFilter.attribute_code)">{{ $t("show_less") }}</b-link>
            </div>
          </div>
        </div>
      </div>

      <div class="rating_summary" v-if="
        prodFilter.attribute_code == 'rating_summary'
      ">
        <span class="filter--block-heading">
          {{ $t(prodFilter.label) }}
        </span>
        <div :id="`brands-filter-${prodFilter.attribute_code}`" class="brands-filter filter-lists">

          <!-- <div class="title-border" v-for="(brandsFilterItem, index) in prodFilter.options" :key="index">
            <div>
              <div class="d-flex justify-content-between title-border">
                <label :for="brandsFilterItem.value" class="d-flex align-items-center">
                  <input type="radio" :key="brandsFilterItem.value" :name="brandsFilterItem.label"
                    :id="brandsFilterItem.value" :ref="brandsFilterItem.value" :value="`attribute_code:` +
                      prodFilter.attribute_code +
                      `,type_label:` +
                      prodFilter.label +
                      `,value:` +
                      brandsFilterItem.value +
                      `,label:` +
                      brandsFilterItem.label
                      " @click="onChangeFilter($event)" />
                  <span class="fake-input"></span>
                  <span class="fake-label">{{ brandsFilterItem.label }}</span>
                </label>

              </div>
            </div>
          </div> -->
          <div class="title-border" v-for="rating_index in ratingFiltersCount" :key="rating_index[0]">
            <div>
              <div class="d-flex justify-content-between title-border">
                <label
                  class="d-flex align-items-center"
                  :class="{ active: activeRating === rating_index[0] }"
                >
                  <input type="radio" name="rating_summery" :value="`attribute_code:` +
                      prodFilter.attribute_code +
                      `,type_label:` +
                      prodFilter.label +
                      `,value:` +
                      rating_index[0] +
                      `,label:` +
                      getOptionLabel(rating_index[0])
                      " @click="onChangeFilterRadio($event,rating_index[0])" />
             
                    <Rating class="m-0" :stars="rating_index[1]" />   
                  <span class="fake-label">{{ getOptionLabel(rating_index[0]) }}</span>
                </label>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="price" v-if="prodFilter.attribute_code == 'price'">
        <b-link
          class="filter--block-heading filter--collapse"
          v-b-toggle.price-filter
          >{{ $t(prodFilter.label) }}</b-link
        >
        <b-collapse visible id="price-filter">
          <div class="range-slider">
            <ClientOnly>
              <VueRangeSlider
                :min="priceFilterMin"
                :max="priceFilterMax"
                :enable-cross="enableCross"
                :formatter="formatter"
                v-model="priceFilter"
                :logarithmic="true"
                :tooltip="null"
                :lazy="true"
                @dragging="onDragging"
              >
              </VueRangeSlider>
            </ClientOnly>

            <div class="range-values d-flex justify-content-between">
              <span>€{{ dragValue[0] ? dragValue[0] : priceFilter[0] }}</span>
              <span>€{{ dragValue[1] ? dragValue[1] : priceFilter[1]   }}</span>
            </div>
          </div>
        </b-collapse>
      </div> -->
    </div>
  </div>
</template>

<script>
import Rating from "@/esf_kerkrade_vitanatura/core/components/Rating";
export default {
  name: "ProductFilterContent",
  components: {Rating},
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },
  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
    this.tooltipDir = "bottom";
  },
  methods: {
    onChangeFilter(event) {
      const evSplit = event.target.value.split(",");
      const filter = {
        attribute_code: "",
        type_label: "",
        value: "",
        label: "",
      };
      evSplit.forEach((element) => {
        const part = element.split(":");
        if (part[0] == "attribute_code") {
          filter.attribute_code = part[1];
        } else if (part[0] == "type_label") {
          filter.type_label = part[1];
        } else if (part[0] == "value") {
          filter.value = part[1];
        } else if (part[0] == "label") {
          filter.label = part[1];
        }
      });
      if (typeof filter.value != "undefined") {
        if (event.target.checked == true) {
          this.$store.commit("category/setActiveFilter", filter);
        } else {
          this.$store.commit("category/removeActiveFilter", filter);
        }
      }
      this.updateUrlFilters();
    },
    processFilterItems() {
      for (const val of this.filterItemsSelected) {
        if (val.attribute_code === "rating_summary") {
          var remove_filter = {value : val.value }
          this.$store.commit("category/removeActiveFilterReplace", remove_filter);
        }
      }
    },
    onChangeFilterRadio(event,value) {
      this.activeRating = value;
      const evSplit = event.target.value.split(",");
      const filter = {
        attribute_code: "",
        type_label: "",
        value: "",
        label: "",
      };

      // Parse the selected value into the filter object
      evSplit.forEach((element) => {
        const part = element.split(":");
        if (part[0] == "attribute_code") {
          filter.attribute_code = part[1];
        } else if (part[0] == "type_label") {
          filter.type_label = part[1];
        } else if (part[0] == "value") {
          filter.value = part[1];
        } else if (part[0] == "label") {
          filter.label = part[1];
        }
      });
    
      this.processFilterItems();
      // Commit the selected filter to the Vuex store
      if (typeof filter.value !== "undefined") {
       this.$store.commit("category/setActiveFilter", filter);
      }
      // Update the URL filters
      this.updateUrlFilters();
    },

    removeSelectedItem(id) {
      const filter = { value: id };
      if (this.$refs[id] && this.$refs[id][0]) {
        this.$refs[id][0].checked = false;
      }
      this.$store.commit("category/removeActiveFilter", filter);
      this.updateUrlFilters();
    },
    removePriceFilter() {
      // const priceFilter = [this.priceFilterMin, this.priceFilterMax];
      // this.$store.commit("category/setPriceFilter", priceFilter);

      const path = "/" + this.category.url_path;
      const query = { ...this.$route.query };
      delete query["filter-price"];
      delete query["page"];

      this.$router.replace({ path, query });
    },
    toggleFilter(event) {
      this.$emit("clicked", event);
    },
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
    },
    updateUrlFilters() {
      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (val.attribute_code == "rating_summary") {
          activeFilters[val.attribute_code] = val.value;
        } else if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const path = "/" + this.category.url_path;
      const query = { ...this.$route.query };
      for (const q in query) {
        if (q.startsWith("filter-") && q !== "filter-price") {
          delete query[q];
        }
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    onDragging(value) {
      this.dragValue = value;
    },
    getOptionLabel(index) {
      return index == 1 ? index + ' Star & Up' : index + ' Stars & up';
    },
  },
  data() {
    return {
      dragValue: [],
      value: [0, 200],
      readMore: {},
      activeRating: null,
      ratingFiltersCount: Array.from({ length : 5 }, (_, i) => [5 - i, (5 - i) * 20])
    };
  },
  computed: {
    filterItemsSelected() {
      return this.$store.getters["category/getActiveFilter"];
    },
    category() {
      var catData = this.$store.getters["category/getCurrentCategory"];
      catData.name =
        catData.name.charAt(0).toUpperCase() + catData.name.slice(1);
      if (catData.children) {
        catData.children.forEach(function (subcat, index) {
          var newName =
            subcat.name.charAt(0).toUpperCase() + subcat.name.slice(1);
          catData.children[index].name = newName;
        });
      }
      return catData;
    },
    // isPriceFilterActive() {
    //   return this.$store.getters["category/getIsPriceFilterActive"];
    // },
    // priceFilterMin() {
    //   return this.$store.getters["category/getPriceFilterMin"];
    // },
    // priceFilterMax() {
    //   return this.$store.getters["category/getPriceFilterMax"];
    // },
    priceFilterLabel() {
      if (this.productFilters) {
        const priceFilter = this.productFilters.find(
          (filter) => filter.attribute_code == "price"
        );
        return priceFilter && priceFilter.label;
      }
      return null;
    },
    // priceFilter: {
    //   get: function () {
    //     return this.$store.getters["category/getPriceFilter"];
    //   },
    //   set: function (val) {
    //     this.$store.commit("category/setPriceFilter", val);

    //     const path = "/" + this.category.url_path;
    //     const query = { ...this.$route.query };
    //     if (this.isPriceFilterActive) {
    //       query["filter-price"] = `${val[0]}-${val[1]}`;
    //     } else {
    //       delete query["filter-price"];
    //     }
    //     delete query["page"];

    //     this.$router.replace({ path, query });
    //   },
    // },
    productFilters: {
      get: function () {
        // Get a deep copy of the product filters
        let prodFilters = JSON.parse(
          JSON.stringify(this.$store.getters["category/getProductFilters"])
        );
        if (prodFilters != null && Symbol.iterator in Object(prodFilters)) {
          prodFilters.forEach(function (filter, index) {

            // Capitalize the label of each filter
            filter.label =
              filter.label.charAt(0).toUpperCase() + filter.label.slice(1);
            // Capitalize the label of each option
            filter.options.forEach(
              (option, optIndex) =>
              (prodFilters[index].options[optIndex].label =
                option.label.charAt(0).toUpperCase() + option.label.slice(1))
            );
          });
        }
        return prodFilters;
      },
      set: function () { },
    },
    categories() {
      return this.category.children;
    },
  },
  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },
  watch: {
    filterItemsSelected: function () { },
  },
  mounted() {
    this.filterItemsSelected.forEach(
      (filterItem) => (this.$refs[filterItem.value][0].checked = true)
    );
  },
};
</script>
