<template>
  <div class="content-filter">
    <div class="pagination-bar-down">
      <div class="left d-none d-lg-flex">
        <div class="display-drop d-md-flex align-items-center">
          <label>{{ $t("view_as") }}  </label>
          <button class="lauout-switcher bg-transparent" @click="changeCategoryColumns(2)"
            :class="categoryColumns == 2 ? 'active' : ''">
            <span class="lines"></span><span class="lines"></span>
          </button>
          <button class="lauout-switcher bg-transparent" @click="changeCategoryColumns(3)"
            :class="categoryColumns == 3 ? 'active' : ''">
            <span class="lines"></span><span class="lines"></span>
            <span class="lines"></span>
          </button>
          <button class="lauout-switcher bg-transparent" @click="changeCategoryColumns(4)"
            :class="categoryColumns == 4 ? 'active' : ''">
            <span class="lines"></span><span class="lines"></span>
            <span class="lines"></span><span class="lines"></span>
          </button>
        </div>
      </div>
      <div class="select-wrapper">
        <b-form-select id="sorting" :options="selectOptions" class="select" @change="changeSorting" :value="sorting" />
      </div>
    </div>
    <div class="cst-pagination-bar">
      <!-- <label
        >Pagina<span>{{ currentPage }}</span
        >van<span>{{ Math.ceil(totalProds / prodsPerPage) }}</span>
      </label> -->
      <b-pagination
        :total-rows="totalProds"
        :per-page="prodsPerPage"
        :value="currentPage"
        @change="changePage"
        class="cst-pagination"
        aria-controls="cat-item"
      >
        <template #page="{ page }">
          <b-link class="page-link" :to="goToPage(page)">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>
  
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "SearchPageContentFilter",
  mounted() {
    const { page } = this.$route.query;
    const { path } = this.$route;
    const query = { ...this.$route.query };
    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }
    const pageSize = config.products?.perPage;
    const perPage = this.$store.getters["search/getProductsPerPage"];
    if (perPage != pageSize) query["page-size"] = perPage;

    this.$router.replace({ path, query });
  },
  computed: {
    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    categoryColumns() {
      return this.$store.state.category_theme.categoryColumns
    },
    sorting() {
      return this.$store.getters["search/getSorting"];
    },
    currentPage() {
      return this.$store.getters["search/getCurrentPage"];
    },
    totalProds() {
      return this.$store.getters["search/getTotalProducts"];
    },
    prodsPerPage() {
      return this.$store.getters["search/getProductsPerPage"];
    },
    totalPages() {
      return this.$store.getters["search/getTotalPages"];
    },
  },
  methods: {
    changePage(pageNo) {
      this.$store.commit("search/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    changeCategoryColumns(value) {
      this.$store.commit('category_theme/setCategoryColumns', value)
    },
    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });

      this.$store.commit("search/setProductsPerPage", perPage);
    },
    changeSorting(sortVal) {
      this.$store.commit("search/setSorting", sortVal);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      const { sortDefault } = config;
      if (config.sortingOptions[sortVal] && sortVal !== sortDefault) {
        query["sort"] = sortVal;
      } else {
        delete query["sort"];
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
  },
  data: () => ({}),
};
</script>
